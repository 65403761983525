<template>
  <b-card
    v-if="data"
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h6>MPG</h6>
    <h2 class="font-weight-bolder mb-1">
      {{parseFloat(Math.floor(data[0].data[data[0].data.length - 1]))}}
    </h2>
    <!-- chart -->
    <vue-apex-charts
      v-if="data[0].data.length"
      height="70"
      :options="statisticsProfit.chartOptions"
      :series="data"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    wedStatus: {
      type: Boolean
    }
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            categories: [],
            labels: {
              show: true,
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  created() {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
        
    var week = Math.ceil(days / 7);
    var weekArray = [];
    var i = 0;

    while(weekArray.length < 8) {

      if (week - i === 0) {
        week = 52;
        i = 0;
      }
      
      weekArray.push(week - i)

      i++;
    }

    if (this.wedStatus)
      this.statisticsProfit.chartOptions.xaxis.categories = weekArray.reverse().slice(1, weekArray.length-1);
    else this.statisticsProfit.chartOptions.xaxis.categories = weekArray.reverse().slice(0, weekArray.length-2);
  }
}
</script>
