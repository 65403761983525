<template>
    <section id="dashboard-ecommerce">
        <b-row class="match-height">
            <b-col lg="6" md="6" sm="12">
                <ecommerce-statistics
                    v-if="data.statisticsItems"
                    :data="data.statisticsItems"
                    :week="week"
                    :wedStatus="thisWednesdayStatus"
                    @selectedWeek="selectWeek"
                />
            </b-col>
            <!-- Bar Chart - Orders -->
            <b-col lg="4" md="4" sm="12">
                <ecommerce-fuel-chart
                    v-if="data.statisticsFuel.length"
                    :data="data.statisticsFuel"
                    :wedStatus="thisWednesdayStatus"
                />
            </b-col>
            <!--/ Bar Chart - Orders -->
            <b-col lg="2" md="2" sm="12">
                <ecommerce-vehicle-chart :data="data.statisticsVehicle" :wedStatus="thisWednesdayStatus" />
            </b-col>
        </b-row>

        <b-row class="match-height">
            <b-col lg="5">
                <b-row class="match-height">
                    <!-- Bar Chart - Orders -->
                    <b-col lg="7" md="7" sm="7">
                        <ecommerce-order-chart :data="data.statisticsPayroll" :wedStatus="thisWednesdayStatus" />
                    </b-col>
                    <!--/ Bar Chart - Orders -->
                    <b-col lg="5" md="5" sm="5">
                        <ecommerce-profit-chart :data="data.statisticsMiles" :wedStatus="thisWednesdayStatus" />
                    </b-col>
                    <b-col lg="12" md="12" sm="12">
                        <ecommerce-earnings-chart :data="data.earningsChart" :wedStatus="thisWednesdayStatus" />
                    </b-col>
                </b-row>
            </b-col>

            <!-- Revenue Report Card -->
            <b-col lg="7">
                <ecommerce-revenue-report :data="data.revenue" :wedStatus="thisWednesdayStatus" />
            </b-col>
            <!--/ Revenue Report Card -->
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceFuelChart from "./EcommerceFuelChart.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceVehicleChart from "./EcommerceVehicleChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import axios from "axios";
import { serverUri } from "@/config";
import { join } from 'postcss-rtl/lib/affected-props';

export default {
    components: {
        BRow,
        BCol,

        EcommerceMedal,
        EcommerceStatistics,
        EcommerceRevenueReport,
        EcommerceFuelChart,
        EcommerceOrderChart,
        EcommerceProfitChart,
        EcommerceEarningsChart,
        EcommerceCompanyTable,
        EcommerceMeetup,
        EcommerceBrowserStates,
        EcommerceGoalOverview,
        EcommerceTransactions,
        EcommerceVehicleChart,
    },
    data() {
        return {
            data: {
                revenue: {
                    years: ["2022", "2019", "2018"],
                    price: "25,852",
                    budget: "56,800",
                    revenueReport: [
                        {
                            name: "Earning",
                            data: [],
                        },
                        {
                            name: "Expense",
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                    ],
                    budgetChart: {
                        series: [
                            {
                                data: [
                                    61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62,
                                ],
                            },
                            {
                                data: [
                                    20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27,
                                ],
                            },
                        ],
                    },
                },
                earningsChart: {
                    missed: [],
                    displayMissValue: 0,
                    outside: [],
                    displayOutside: 0
                },
                statisticsFuel: [{ data: [], name: "2020" }],
                statisticsMiles: [{ data: [] }],
                statisticsVehicle: [{ data: [] }],
                statisticsPayroll: [{ data: [] }],
                statisticsItems: [
                    {
                        icon: "DollarSignIcon",
                        color: "light-primary",
                        title: 0,
                        subtitle: "Revenue",
                        customClass: "mb-2 mb-xl-0",
                    },
                    {
                        icon: "TrendingUpIcon",
                        color: "light-info",
                        title: 0,
                        subtitle: "Miles",
                        customClass: "mb-2 mb-xl-0",
                    },
                    {
                        icon: "BoxIcon",
                        color: "light-danger",
                        title: 0,
                        subtitle: "Fuel",
                        customClass: "mb-2 mb-sm-0",
                    },
                    {
                        icon: "UserIcon",
                        color: "light-success",
                        title: 0,
                        subtitle: "Drivers",
                        customClass: "",
                    },
                ],
            },
            week: null,
            year: null,
            thisWednesdayStatus: false,
            fuelArray: [],
            milesArray: [],
            revenueArray: [],
            driversArray: [],
            payrollArray: [],
            receiptsArray: []
        };
    },
    created() {


        if(getUserData().driver_id != undefined)
        {
            this.$router.push({
                    name: "driver-dashboard",
                });

        } 

        this.getWeekAndYearAndWednesday();

        var dailyAmtTotal = [];
        var otherAmtTotal = [];

        axios.get(`${serverUri}/dashboard/miles`).then((res) => {

            if (this.week <= 11) {
                let j = 0;
                for (let i = 11 - this.week; i >= 0; i --, j ++) {
                    // Miles
                    this.milesArray[j] = res.data.result1.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    )[0];
                    this.milesArray[j] = this.milesArray[j] ? this.milesArray[j].totalMiles + this.milesArray[j].spotMiles : 0;

                    // Revenue
                    dailyAmtTotal[j] = res.data.result1.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    )[0];
        
                    otherAmtTotal[j] = res.data.result2.filter(
                        (item) => 
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    )[0];
                    
                    this.revenueArray[j] = dailyAmtTotal[j] && otherAmtTotal[j]
                        ? (dailyAmtTotal[j].totalRevenue + otherAmtTotal[j].otherAmtTotal).toFixed(2)
                        : 0;
                }

                for (let i = 0; i < this.week; i ++, j++) {
                    // Miles
                    this.milesArray[j] = res.data.result1.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    )[0];
                    this.milesArray[j] = this.milesArray[j] ? this.milesArray[j].totalMiles + this.milesArray[j].spotMiles : 0;

                    // Revenue
                    dailyAmtTotal[j] = res.data.result1.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    )[0];
        
                    otherAmtTotal[j] = res.data.result2.filter(
                        (item) => 
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    )[0];
                    
                    this.revenueArray[j] = dailyAmtTotal[j] && otherAmtTotal[j]
                        ? (dailyAmtTotal[j].totalRevenue + otherAmtTotal[j].otherAmtTotal).toFixed(2)
                        : 0;
                }
            } else {                
                for (let i = 0; i < 12; i++) {
                    // Miles
                    this.milesArray[i] = res.data.result1.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    )[0];
                    this.milesArray[i] = this.milesArray[i] ? this.milesArray[i].totalMiles + this.milesArray[i].spotMiles : 0;

                    // Revenue
                    dailyAmtTotal[i] = res.data.result1.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    )[0];
        
                    otherAmtTotal[i] = res.data.result2.filter(
                        (item) => 
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    )[0];
                    
                    this.revenueArray[i] = dailyAmtTotal[i] && otherAmtTotal[i]
                        ? (dailyAmtTotal[i].totalRevenue + otherAmtTotal[i].otherAmtTotal).toFixed(2)
                        : 0;
                }
            }

            if (this.thisWednesdayStatus) {
                this.data.revenue.revenueReport[0].data = this.revenueArray.slice(1, this.revenueArray.length-1).map(item => parseInt(item));
                this.data.statisticsMiles[0].data = this.milesArray.slice(5, this.milesArray.length-1);
                // this.data.statisticsFuel[0].data = this.fuelArray.slice(4, this.fuelArray.length-1);

                this.data.statisticsItems[0].title = this.revenueArray[this.revenueArray.length - 2];
                this.data.statisticsItems[1].title = this.milesArray[this.milesArray.length - 2];
                // this.data.statisticsItems[2].title = this.fuelArray[this.fuelArray.length - 2];
            }
            else {
                this.data.revenue.revenueReport[0].data = this.revenueArray.slice(0, this.revenueArray.length-2).map(item => parseInt(item));
                this.data.statisticsMiles[0].data = this.milesArray.slice(4, this.milesArray.length-2);
                // this.data.statisticsFuel[0].data = this.fuelArray.slice(4, this.fuelArray.length-2);

                this.data.statisticsItems[0].title = this.revenueArray[this.revenueArray.length - 3];
                this.data.statisticsItems[1].title = this.milesArray[this.milesArray.length - 3];
                // this.data.statisticsItems[2].title = this.fuelArray[this.fuelArray.length - 3];
            }
            
        });

        // Payroll Items
        
        const company = getUserData().company_id;
        axios.get(`${serverUri}/dashboard/payroll/${company}`).then((res) => {

            var array = [];

            if (this.week <= 11) {
                let j = 0;
                for (let i = 11 - this.week; i >= 0; i --, j ++) {
                    this.payrollArray[j] = 0;

                    array[j] = res.data.tripData.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    );

                    array[j].length && array[j].map((item1, index) => {
                        const filtering = res.data.rateSetting.filter(item2 => item1.miles_qty >= item2.from_miles && item1.miles_qty <= item2.to_miles)

                        this.payrollArray[j] += filtering.length ? filtering[0].fixed_rate : item1.price_per_mile * item1.miles_qty;                    
                    })
                }

                for (let i = 0; i < this.week; i ++, j ++) {
                    this.payrollArray[j] = 0;

                    array[j] = res.data.tripData.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    );

                    array[j].length && array[j].map((item1, index) => {
                        const filtering = res.data.rateSetting.filter(item2 => item1.miles_qty >= item2.from_miles && item1.miles_qty <= item2.to_miles)

                        this.payrollArray[j] += filtering.length ? filtering[0].fixed_rate : item1.price_per_mile * item1.miles_qty;                    
                    })
                }

                res.data.spotData.map((item1, index) => {
                    const filtering = res.data.spotSetting.filter(
                        (item2) =>
                            item1.miles >= item2.from_miles &&
                            item1.miles <= item2.to_miles
                    );

                    this.payrollArray.map(item3 => {
                        item3 += filtering.length ? filtering[0].spot_pay : item1.miles * item1.price_per_mile;
                    })
                })
            } else {

                for (let i = 0; i < 12; i ++) {
                    this.payrollArray[i] = 0;

                    array[i] = res.data.tripData.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    );

                    array[i].length && array[i].map((item1, index) => {
                        const filtering = res.data.rateSetting.filter(item2 => item1.miles_qty >= item2.from_miles && item1.miles_qty <= item2.to_miles)

                        this.payrollArray[i] += filtering.length ? filtering[0].fixed_rate : item1.price_per_mile * item1.miles_qty;                    
                    }) 

                    res.data.spotData.map((item1, index) => {
                        const filtering = res.data.spotSetting.filter(
                            (item2) =>
                                item1.miles >= item2.from_miles &&
                                item1.miles <= item2.to_miles
                        );

                        this.payrollArray.map(item3 => {
                            item3 += filtering.length ? filtering[0].spot_pay : item1.miles * item1.price_per_mile;
                        })
                    })
                }
            }

            // Payroll, Revenue Items
            if (this.thisWednesdayStatus) {
                this.data.statisticsPayroll[0].data = this.payrollArray.slice(5, this.payrollArray.length-1);

                this.data.statisticsItems[3].title = this.driversArray[this.driversArray.length - 2];

            } else {
                this.data.statisticsPayroll[0].data = this.payrollArray.slice(4, this.payrollArray.length-2);

                this.data.statisticsItems[3].title = this.driversArray[this.driversArray.length - 3];
            }
        });

        // Fuel Purchase vs Receipts
        axios.get(`${serverUri}/dashboard/receipt`).then((res) => {
            
            var vehicleArray = [];

            if (this.week <= 11) {
                let  j = 0;
                for (let i = 11 - this.week; i >= 0; i --, j ++) {
                    // Fuel
                    this.fuelArray[j] = res.data.totalFuel.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    )[0];
                    this.fuelArray[j] = this.fuelArray[j] ? this.fuelArray[j].totalFuelPurchase : 0;

                    vehicleArray[j] =
                        this.fuelArray[j] != 0
                            ? (
                                parseInt(this.milesArray[j]) /
                                this.fuelArray[j]
                            ).toFixed(3)
                            : 0;

                    //receipts
                    this.receiptsArray[j] = res.data.totalReceipts.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    )[0];
                    this.receiptsArray[j] = this.receiptsArray[j] ? this.receiptsArray[j].totalFuelReceipts : 0;

                    this.data.earningsChart.missed[j] = this.fuelArray[j] - this.receiptsArray[j]
                }

                for (let i = 0; i < this.week; i++, j++) {
                    // Fuel
                    this.fuelArray[j] = res.data.totalFuel.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    )[0];
                    this.fuelArray[j] = this.fuelArray[j] ? this.fuelArray[j].totalFuelPurchase : 0;

                    vehicleArray[j] =
                        this.fuelArray[join] != 0
                            ? (
                                parseInt(this.milesArray[j]) /
                                this.fuelArray[j]
                            ).toFixed(3)
                            : 0;

                    //receipts
                    this.receiptsArray[j] = res.data.totalReceipts.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    )[0];
                    this.receiptsArray[j] = this.receiptsArray[j] ? this.receiptsArray[j].totalFuelReceipts : 0;

                    this.data.earningsChart.missed[j] = this.fuelArray[j] - this.receiptsArray[j]
                }
            } else {
                for (let i = 0; i < 12; i++) {
                    // Fuel
                    this.fuelArray[i] = res.data.totalFuel.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    )[0];
                    this.fuelArray[i] = this.fuelArray[i] ? this.fuelArray[i].totalFuelPurchase : 0;

                    vehicleArray[i] =
                        this.fuelArray[i] != 0
                            ? (
                                parseInt(this.milesArray[i]) /
                                this.fuelArray[i]
                            ).toFixed(3)
                            : 0;

                    //receipts
                    this.receiptsArray[i] = res.data.totalReceipts.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    )[0];
                    this.receiptsArray[i] = this.receiptsArray[i] ? this.receiptsArray[i].totalFuelReceipts : 0;

                    this.data.earningsChart.missed[i] = this.fuelArray[i] - this.receiptsArray[i]
                }
            }
            
            // this.data.satisticsFuel[0].name = year;
            // this.data.statisticsFuel[0].data = fuelArray;

            if (this.thisWednesdayStatus) {
                this.data.statisticsVehicle[0].data = vehicleArray.slice(5, vehicleArray.length-1);
                this.data.statisticsFuel[0].data = this.fuelArray.slice(4, this.fuelArray.length-1);
                this.data.statisticsItems[2].title = this.fuelArray[this.fuelArray.length - 2];
                this.data.earningsChart.displayMissValue = this.data.earningsChart.missed[this.data.earningsChart.missed.length-2];
                // this.data.statisticsFuel[0].data = fuelArray.slice(1, fuelArray.length-1);
            } else {
                this.data.statisticsVehicle[0].data = vehicleArray.slice(4, vehicleArray.length-2);                
                this.data.statisticsFuel[0].data = this.fuelArray.slice(4, this.fuelArray.length-2);
                this.data.statisticsItems[2].title = this.fuelArray[this.fuelArray.length - 3];
                this.data.earningsChart.displayMissValue = this.data.earningsChart.missed[this.data.earningsChart.missed.length-3];
                // this.data.statisticsFuel[0].data = fuelArray.slice(0, fuelArray.length-2);
            }

            // SatisticsItems
            // this.data.statisticsItems[2].title =
            //     fuelArray[fuelArray.length - 1];

            // this.data.earningsChart.series.push(
            //     fuelArray[fuelArray.length - 1]
            //         ? fuelArray[fuelArray.length - 1]
            //         : 0
            // );
            // this.data.earningsChart.series.push(
            //     res.data.receipt[0].totalReceipt
            //         ? res.data.receipt[0].totalReceipt
            //         : 0
            // );
            // this.data.earningsChart.count = res.data.receipt[0].totalNum
            //     ? (res.data.count / res.data.receipt[0].totalNum) * 100
            //     : 0;
        });

        axios.get(`${serverUri}/dashboard/fueloutside`).then((res) => {
            var tempFedex = [];
            var tempTotalTruckStops = [];

            if (this.week <= 11) {
                let j = 0;
                for (let i = 11 - this.week; i >= 0; i--, j ++) {
                    
                    tempFedex[j] = res.data.fedexgr.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    )[0];
                    tempFedex[j] = tempFedex[j] ? tempFedex[j].fedexgr : 0;

                    tempTotalTruckStops[j] = res.data.totalTruckStops.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            (this.year-1) * 59 + 52 - i
                    )[0];
                    tempTotalTruckStops[j] = tempTotalTruckStops[j] ? tempTotalTruckStops[j].totalTruckStops : 0;

                    this.data.earningsChart.outside[j] = 
                        tempTotalTruckStops[j] > 0 ? ((tempTotalTruckStops[j] - tempFedex[j]) / tempTotalTruckStops[j]) * 100 : 0;
                }

                for (let i = 0; i < this.week; i++, j ++) {
                    
                    tempFedex[j] = res.data.fedexgr.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    )[0];
                    tempFedex[j] = tempFedex[j] ? tempFedex[j].fedexgr : 0;

                    tempTotalTruckStops[j] = res.data.totalTruckStops.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + 1 + i
                    )[0];
                    tempTotalTruckStops[j] = tempTotalTruckStops[j] ? tempTotalTruckStops[j].totalTruckStops : 0;

                    this.data.earningsChart.outside[j] = 
                        tempTotalTruckStops[j] > 0 ? ((tempTotalTruckStops[j] - tempFedex[j]) / tempTotalTruckStops[j]) * 100 : 0;
                }
            } else {
                for (let i = 0; i < this.week; i++) {
                    
                    tempFedex[i] = res.data.fedexgr.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    )[0];
                    tempFedex[i] = tempFedex[i] ? tempFedex[i].fedexgr : 0;

                    tempTotalTruckStops[i] = res.data.totalTruckStops.filter(
                        (item) =>
                            item.year_num * 59 + item.week_num ===
                            this.year * 59 + this.week - 11 + i
                    )[0];
                    tempTotalTruckStops[i] = tempTotalTruckStops[i] ? tempTotalTruckStops[i].totalTruckStops : 0;

                    this.data.earningsChart.outside[i] = 
                        tempTotalTruckStops[i] > 0 ? ((tempTotalTruckStops[i] - tempFedex[i]) / tempTotalTruckStops[i]) * 100 : 0;
                }
            }

            if (this.thisWednesdayStatus) {
                this.data.earningsChart.displayOutside = this.data.earningsChart.outside[this.data.earningsChart.outside.length-2];
            } else {
                this.data.earningsChart.displayOutside = this.data.earningsChart.outside[this.data.earningsChart.outside.length-3];
            }
            
        });
    },
    methods: {
        getWeekAndYearAndWednesday() {
            const currentDate = new Date();
            const startDate = new Date(currentDate.getFullYear(), 0, 1);
            var days = Math.floor(
                (currentDate - startDate) / (24 * 60 * 60 * 1000)
            );

            this.year = currentDate.getFullYear();
            this.week = Math.ceil(days / 7);

            let dayOfWeekNumber = currentDate.getDay();
            if (dayOfWeekNumber >= 3) this.thisWednesdayStatus = !this.thisWednesdayStatus
        },
        selectWeek(selectedWeek) {
            this.data.statisticsItems[0].title = this.revenueArray[this.fuelArray.length-1-selectedWeek];
            this.data.statisticsItems[1].title = this.milesArray[this.fuelArray.length-1-selectedWeek];
            this.data.statisticsItems[2].title = this.fuelArray[this.fuelArray.length-1-selectedWeek];
            this.data.statisticsItems[3].title = this.driversArray[this.fuelArray.length-1-selectedWeek];
            this.data.earningsChart.displayMissValue = this.data.earningsChart.missed[this.data.earningsChart.missed.length-1-selectedWeek];
            this.data.earningsChart.displayOutside = this.data.earningsChart.outside[this.data.earningsChart.outside.length-1-selectedWeek];
        }
    }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
