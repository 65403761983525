<template>
    <b-card v-if="data" no-body class="card-statistics">
        <b-card-header>
            <b-card-title>Statistics</b-card-title>
            <v-select
                style="width: 150px"
                v-model="selectedWeek"
                @input="onChangeWeek(selectedWeek)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="week"
                :options="weeks"
            />
        </b-card-header>
        <b-card-body class="statistics-body">
            <b-row>
                <b-col
                    v-for="item in data"
                    :key="item.icon"
                    xl="3"
                    sm="6"
                    :class="item.customClass"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" :variant="item.color">
                                <feather-icon size="24" :icon="item.icon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{
                                    item.subtitle === "Revenue" ||
                                    item.subtitle === "Fuel"
                                        ? "$"
                                        : ""
                                }}
                                {{
                                    parseInt(item.title).toLocaleString(
                                        "en-US"
                                    )
                                }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ item.subtitle }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
} from "bootstrap-vue";
import axios from "axios";
import { serverUri } from "@/config";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        vSelect,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        week: {
            type: Number
        },
        wedStatus: {
            type: Boolean
        }
    },
    data() {
        return {
            weeks: [],
            selectedWeek: 0,
        };
    },
    methods: {
        onChangeWeek(week) {
            this.$emit('selectedWeek', this.weeks.indexOf(week));
        }
    },
    created() {
        const lastWeek = 52;
        for (let i = 0; i < 12 ; i ++) {
            if ((this.week - i) < 1) this.weeks[i] = this.week - i + lastWeek;
            else this.weeks[i] = this.week - i;
        }

        this.wedStatus ? this.selectedWeek=this.weeks[1] : this.selectedWeek=this.weeks[2];
    }
    
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
