<template>
    <b-card v-if="data" body-class="pb-50">
        <h6>Payroll</h6>
        <h2 class="font-weight-bolder mb-1">
            ${{
                parseFloat(
                    Math.floor(data[0].data[data[0].data.length - 1])
                ).toLocaleString("en-US")
            }}
        </h2>
        <!-- chart -->
        <vue-apex-charts
            v-if="data[0].data.length"
            height="70"
            :options="statisticsOrder.chartOptions"
            :series="data"
        />
    </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $barColor = "#f3f3f3";

export default {
    components: {
        BCard,
        VueApexCharts,
    },
    props: {
        data: {
            type: Array,
            default: () => {},
        },
        wedStatus: {
            type: Boolean
        }
    },
    data() {
        return {
            statisticsOrder: {
                chartOptions: {
                    chart: {
                        type: "bar",
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                    },
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0,
                            top: -15,
                            bottom: -15,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "20%",
                            startingShape: "rounded",
                            colors: {
                                backgroundBarColors: [
                                    $barColor,
                                    $barColor,
                                    $barColor,
                                    $barColor,
                                    $barColor,
                                ],
                                backgroundBarRadius: 5,
                            },
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: [$themeColors.warning],

                    xaxis: {
                        categories: [],
                        labels: {
                            show: true,
                            style: {
                                colors: '#6E6B7B',
                                fontSize: '0.96rem',
                                fontFamily: 'Montserrat',
                            },
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                    },
                    yaxis: {
                        show: false,
                    },
                    tooltip: {
                        x: {
                            show: false,
                        },
                    },
                },
            },
        };
    },
    created() {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
            
        var week = Math.ceil(days / 7);
        var weekArray = [];
        var i = 0;

        while(weekArray.length < 8) {

            if (week - i === 0) {
                week = 52;
                i = 0;
            }
            
            weekArray.push(week - i)

            i++;
        }

        if (this.wedStatus)
            this.statisticsOrder.chartOptions.xaxis.categories = weekArray.reverse().slice(1, weekArray.length-1);
        else this.statisticsOrder.chartOptions.xaxis.categories = weekArray.reverse().slice(0, weekArray.length-2);
    }
};
</script>
